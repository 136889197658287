<template>
  <v-tabs vertical dark v-model="active">
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Open Orders Status</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-checks</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Pricelist</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-numbered-rtl</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Items Data Pack</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-image-move</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Open Invoices/Payments</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-account-cash-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <!-- <v-tooltip bottom>
      <span>Open Payments</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-account-cash</v-icon>
        </v-tab>
      </template>
    </v-tooltip>-->
    <v-tooltip bottom>
      <span>Statement</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-clipboard-list-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Trackings</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-go-kart-track</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Communications</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-email-open</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tabs-items v-model="active" touchless>
      <v-tab-item>
        <v-container fluid class="py-0">
          <v-alert type="info" dense v-if="record.shippinginstructions">
            <pre>{{ record.shippinginstructions }}</pre>
          </v-alert>
          <v-alert type="error" v-if="record.accountonhold"
            >Account On Hold!</v-alert
          >
          <v-alert type="error" v-if="record.accountonholdinternal"
            >Account On Hold Internal! ({{
              record.creditlimitinternal
            }})</v-alert
          >
          <v-alert type="error" dense v-if="record.inactive"
            >Inactive Account</v-alert
          >
          <v-alert
            type="warning"
            dense
            v-for="instruction of record.additionalinstructions"
            :key="instruction._id"
            >{{ instructions[instruction._id || instruction] }}</v-alert
          >
          <v-alert
            type="success"
            dense
            v-for="(wz, index) of openif"
            :key="index"
            icon="mdi-package-up"
            >Packaging in progress:
            {{ wz.map((r) => r.name).join(", ") }}
            ({{ wz[0].shippingmethod }} - {{ wz[0].shipname }})
          </v-alert>
          <v-alert
            v-if="duplicateemail"
            dense
            icon="mdi-barcode-off"
            type="error"
          >
            Account with this email address already exists
          </v-alert>
          <v-row row wrap>
            <v-col>
              <v-subheader>Main</v-subheader>

              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Enova Type:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ record.customtype ? record.customtype.name : "" }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Enova Number</v-list-item-subtitle
                        >
                        <v-list-item-title>
                          {{ record.number }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Display Name:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.displayname || record.name }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ record.salesrep ? record.salesrep.name : "" }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0" v-if="customerservice">
                        <v-list-item-subtitle
                          >Customer Servie:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ customerservice }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="
                    (record.category && record.category.length) ||
                    record.website
                  "
                ></v-divider>
                <v-list-item
                  v-if="
                    (record.category && record.category.length) ||
                    record.website
                  "
                >
                  <v-list-item-content>
                    <v-row>
                      <v-col
                        class="py-0"
                        v-if="record.category && record.category.length"
                      >
                        <v-list-item-subtitle>Category:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{
                            record.category && record.category.length
                              ? record.category
                                  .map((type) => type.name)
                                  .join(", ")
                              : ""
                          }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0" v-if="record.website">
                        <v-list-item-subtitle>Website</v-list-item-subtitle>
                        <v-list-item-title>
                          <a v-if="record.website" :href="record.website">{{
                            record.website
                          }}</a>
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Type:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{
                            record.isperson
                              ? "Individual Customer"
                              : `Business Customer${record.b2b ? ` (B2B)` : ""}`
                          }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0" v-if="record.taxnumber">
                        <v-list-item-subtitle>Tax Number:</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ record.taxnumber }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Primary Email:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.email }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0" v-if="record.email2">
                        <v-list-item-subtitle
                          >Secondary Email:</v-list-item-subtitle
                        >

                        <v-list-item-title>
                          {{ record.email2 }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Phone:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ record.phone }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item
                  v-if="record.status && record.status._id == 'lead'"
                >
                  <v-list-item-action>
                    <v-icon color="primary">business</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Billing Address:
                          {{ billingaddress.name }}</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          billingaddress.address
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="record.status && record.status._id == 'lead'"
                >
                  <v-list-item-action>
                    <v-icon color="primary">local_shipping</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Shipping Address:
                          {{ shippingaddress.name }}</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          shippingaddress.address
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-subheader>Details</v-subheader>
              <v-list
                v-if="
                  !record.status ||
                  (record.status && record.status._id !== 'lead')
                "
              >
                <v-list-item
                  v-if="record.datefirstsales || record.datelastsales"
                >
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >First Sales:</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          record.datefirstsales
                            ? new Date(record.datefirstsales)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}</v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Last Sales:</v-list-item-subtitle>
                        <v-list-item-title>{{
                          record.datelastsales
                            ? new Date(record.datelastsales)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="record.datefirstsales || record.datelastsales"
                ></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">business</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Billing Address:
                          {{ billingaddress.name }}</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          billingaddress.address
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">local_shipping</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle
                          >Shipping Address:
                          {{ shippingaddress.name }}</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          shippingaddress.address
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item v-if="record.hubspot_id">
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0" v-if="record.hubspot_id">
                        <v-list-item-subtitle>Hubspot</v-list-item-subtitle>
                        <v-list-item-title>
                          <a
                            v-if="record.hubspot_id"
                            :href="`https://app-eu1.hubspot.com/contacts/144831428/record/0-2/${record.hubspot_id}`"
                            >{{ record.name }}</a
                          >
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">comment</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <div class="note grey--text">{{ record.note }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card elevation="0" v-else>
                <v-row class="mt-2 my-3" align="center">
                  <v-col class="py-0">
                    <v-chip class="ma-2" color="green" text-color="white">
                      <v-avatar left class="green darken-4">
                        {{ remainingTasks }}
                      </v-avatar>
                      Remaining to be completed
                    </v-chip>
                  </v-col>

                  <v-divider vertical></v-divider>

                  <v-col class="py-0 mr-2">
                    <v-progress-linear
                      v-model="progress"
                      color="amber"
                      height="25"
                      v-if="progress < 100"
                    ></v-progress-linear>

                    <v-btn
                      v-else
                      color="orange"
                      class="ma-2 white--text"
                      tile
                      block
                      depressed
                      :disabled="
                        !!(record.access && record.access.length) ||
                        $route.params.mode == 'view'
                      "
                      elevation="0"
                      @click="addAccess"
                    >
                      {{
                        !!(record.access && record.access.length)
                          ? "Access Grated"
                          : "Ready to Access"
                      }}
                      <v-icon right dark> mdi-cloud-key </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <v-slide-y-transition class="py-0" group tag="v-list">
                      <template
                        v-for="(task, i) in tasks.filter((v, i) => i % 2 == 0)"
                      >
                        <v-divider
                          v-if="i !== 0"
                          :key="`${i}-divider`"
                        ></v-divider>

                        <v-list-item :key="`${i}-${task.text}`">
                          <v-list-item-action>
                            <v-checkbox
                              v-model="task.done"
                              dense
                              disabled
                              hide-details
                              :color="(task.done && 'grey') || 'primary'"
                            >
                              <template v-slot:label>
                                <div
                                  :class="
                                    (task.done && 'grey--text') ||
                                    'primary--text'
                                  "
                                  class="ml-4"
                                  v-text="task.text"
                                ></div>
                              </template>
                            </v-checkbox>
                          </v-list-item-action>

                          <v-spacer></v-spacer>

                          <v-scroll-x-transition>
                            <v-icon v-if="task.done" color="success">
                              mdi-check
                            </v-icon>
                          </v-scroll-x-transition>
                        </v-list-item>
                      </template>
                    </v-slide-y-transition>
                  </v-col>
                  <v-col>
                    <v-slide-y-transition class="py-0" group tag="v-list">
                      <template
                        v-for="(task, i) in tasks.filter((v, i) => i % 2 != 0)"
                      >
                        <v-divider
                          v-if="i !== 0"
                          :key="`${i}-divider`"
                        ></v-divider>

                        <v-list-item :key="`${i}-${task.text}`">
                          <v-list-item-action>
                            <v-checkbox
                              v-model="task.done"
                              dense
                              hide-details
                              disabled
                              :color="(task.done && 'grey') || 'primary'"
                            >
                              <template v-slot:label>
                                <div
                                  :class="
                                    (task.done && 'grey--text') ||
                                    'primary--text'
                                  "
                                  class="ml-4"
                                  v-text="task.text"
                                ></div>
                              </template>
                            </v-checkbox>
                          </v-list-item-action>

                          <v-spacer></v-spacer>

                          <v-scroll-x-transition>
                            <v-icon v-if="task.done" color="success">
                              mdi-check
                            </v-icon>
                          </v-scroll-x-transition>
                        </v-list-item>
                      </template>
                    </v-slide-y-transition>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col>
              <field-map :addresses="addresses" />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-container fluid class="py-0">
          <v-alert type="info" dense v-if="record.shippinginstructions">
            <pre>{{ record.shippinginstructions }}</pre>
          </v-alert>
          <v-alert type="error" v-if="record.accountonhold"
            >Account On Hold!</v-alert
          >
          <v-alert type="error" dense v-if="record.inactive"
            >Inactive Account</v-alert
          >
        </v-container>
        <tool-openorderstatus :customer="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-relatedtransactions
          :record="record"
          :customheaders="customheaders2"
        />
      </v-tab-item>
      <v-tab-item>
        <tool-pricelist :customer="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-iteminformations :customer="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-openinvoices :customer="record._id" />
      </v-tab-item>
      <!-- <v-tab-item>
      <tool-openpayments :customer="record._id" />
    </v-tab-item>-->
      <v-tab-item>
        <tool-statement :customer="record" />
      </v-tab-item>
      <v-tab-item>
        <statistics :customer="record" />
      </v-tab-item>
      <v-tab-item>
        <tool-trackings :customer="record._id" />
      </v-tab-item>
      <v-tab-item>
        <tool-inbox :record="record" :customer="record._id" filter="user" />
      </v-tab-item>
      <v-tab-item>
        <tool-logs :record="record._id" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import statistics from "../../../components/tools/customerstatistics/View";
export default {
  props: ["record"],
  data() {
    return {
      active: 0,
      duplicateemail: false,
      loading: false,
      photosrc: "",
      instructions: {},
      openif: {},
      customerservice: "",
      customheaders2: [
        { text: "Location", value: "location" },
        { text: "Date", value: "date" },
        { text: "Customer", value: "customer" },
        { text: "Transaction", value: "transaction" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Shipping Method", value: "shippingmethod" },
        { text: "Shipp Address", value: "shippingaddress" },
        { text: "Type", value: "recordtype" },
        { text: "Status", value: "status" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount", summary: true },
        { text: "Gross Amount", value: "grossamount", summary: true },
        { text: "Quantity", value: "quantity", summary: true },
        {
          text: "Quantity Committed",
          value: "quantitycommitted",
          summary: true,
        },
        { text: "Quantity Received", value: "quantityreceived", summary: true },
        { text: "Quantity Billed", value: "quantitybilled", summary: true },
        {
          text: "Inventory Valuation",
          value: "inventoryvaluation",
        },
        { text: "Memo", value: "memo" },
      ],
      tasks: [
        {
          done: false,
          text: "Source",
          field: "source",
        },
        {
          done: false,
          text: "Primary Email",
          field: "email",
        },
        {
          done: false,
          text: "Category",
          field: "category",
        },
        {
          done: false,
          text: "Website",
          field: "website",
        },
        {
          done: false,
          text: "Company/Warehouse",
          field: "location",
        },

        {
          done: false,
          text: "Tax Number",
          field: "taxnumber",
        },
        {
          done: false,
          text: "Tax Rate",
          field: "tax",
        },
        {
          done: false,
          text: "Enova Type",
          field: "customtype",
        },
        {
          done: false,
          text: "Currency",
          field: "currency",
        },
        {
          done: false,
          text: "Terms",
          field: "terms",
        },
        {
          done: false,
          text: "Billing Address",
          field: "billingaddress",
        },
        {
          done: false,
          text: "Ship. Address",
          field: "shippingaddress",
        },
        {
          done: false,
          text: "Contact",
          field: "contacts",
        },
        {
          done: false,
          text: "Price Levels",
          field: "grouppricing",
        },
      ],
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {
        this.tasks.forEach(
          (task) =>
            (task.done = !(
              !this.record[task.field] ||
              (Array.isArray(this.record[task.field]) &&
                !this.record[task.field].length)
            ))
        );
        if (this.record.email) {
          this.duplicateemail = await service("users", {
            action: "findOne",
            data: [
              {
                _id: { $ne: this.record._id },
                email: this.record.email,
                inactive: { $ne: true },
              },
            ],
          })
            .catch((err) => false)
            .then((res) => {
              return res ? true : false;
            });
        }
      },
      deep: true,
    },
  },
  async created() {
    this.tasks.forEach(
      (task) =>
        (task.done = !(
          !this.record[task.field] ||
          (Array.isArray(this.record[task.field]) &&
            !this.record[task.field].length) ||
          (task.field == "billingaddress"
            ? !this.record.billcountry ||
              !this.record.billcity ||
              !this.record.billaddress
            : false)
        ))
    );

    this.instructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "additionalinstruction" }],
    }).then((response) => {
      let ids = {};
      response.map((v) => {
        ids[v._id] = v.name;
      });
      return ids;
    });
    if (
      this.record.location &&
      this.record.location._id != "61e14c6ae4a35a668517a8fe"
    )
      this.openif = await this.getopenif();
    this.customerservice = await this.getCustomerservice();

    for (let [key, value] of Object.entries(this.$route.query)) {
      if (key == "headers-active") this.active = parseInt(value);
    }
  },
  methods: {
    addAccess() {
      this.$set(this.record, "access", this.record.access || []);
      this.record.access.push({
        source: this.record.source,
        email: this.record.email,
        role: {
          _id: "role_customer",
          name: "Customer",
          recordtype: "role",
          collection: "roles",
        },
      });
      this.$set(this.record, "status", {
        _id: "newcustomer",
        name: "New Customer",
        recordtype: "customerstatus",
        collection: "classification",
      });
    },
    async getCustomerservice() {
      if (this.record.salesrep) {
        let salesrep = await service("users", {
          action: "findOne",
          data: [
            { _id: this.record.salesrep._id },
            {
              projection: {
                _id: true,
                salesrep: true,
              },
            },
          ],
        }).then((res) => res.salesrep);
        if (salesrep) {
          let customerservice = await service("users", {
            action: "findOne",
            data: [
              { _id: salesrep },
              {
                projection: {
                  _id: true,
                  name: true,
                },
              },
            ],
          }).then((res) => res);
          return customerservice.name;
        }
        return "";
      } else return "";
    },
    async getopenif() {
      console.log("openif");
      return await service("transactions", {
        action: "find",
        data: [
          {
            recordtype: "itemfulfillment",
            status: { $nin: ["shipped", "packed"] },
            customer: this.record._id,
          },
          {
            projection: {
              _id: true,
              name: true,
              shippingmethod: true,
              shipname: true,
            },
          },
        ],
      })
        .then(async (res) => {
          for (let line of res) {
            line.shippingmethod = await service("items", {
              action: "findOne",
              data: [
                {
                  _id: line.shippingmethod,
                },
                {
                  projection: {
                    _id: true,
                    name: true,
                  },
                },
              ],
            });
          }
          return res;
        })
        .then(async (res) => {
          return res.reduce((t, l) => {
            t[`${l.shipname}-${l.shippingmethod._id}`] =
              t[`${l.shipname}-${l.shippingmethod._id}`] || [];

            t[`${l.shipname}-${l.shippingmethod._id}`].push({
              name: l.name,
              shippingmethod: l.shippingmethod.name,
              shipname: l.shipname,
            });
            return t;
          }, {});
        });
    },
  },
  computed: {
    completedTasks() {
      return this.tasks.filter((task) => task.done).length;
    },
    progress() {
      return (this.completedTasks / this.tasks.length) * 100;
    },
    remainingTasks() {
      return this.tasks.length - this.completedTasks;
    },

    addresses() {
      let addresses = [];
      if (this.record.billingaddress) {
        this.record.billingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png";
        addresses.push(this.record.billingaddress);
      }
      if (this.record.shippingaddress) {
        this.record.shippingaddress.image =
          "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png";
        addresses.push(this.record.shippingaddress);
      }
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "" };
      if (this.record.billingaddress) {
        address.name =
          this.record.billingaddress.addressee ||
          this.record.billingaddress.name;
        if (this.record.billingaddress.address)
          address.address += this.record.billingaddress.address;
        if (this.record.billingaddress.address2)
          address.address += `, ${this.record.billingaddress.address2}`;
        if (this.record.billingaddress.zip)
          address.address += `, ${this.record.billingaddress.zip}`;
        if (this.record.billingaddress.city)
          address.address += `, ${this.record.billingaddress.city}`;
        if (this.record.billingaddress.country)
          address.address += `, ${
            this.record.billingaddress.country.name ||
            this.record.billingaddress.country
          }`;
      }
      return address;
    },
    shippingaddress() {
      let address = { name: "", address: "" };
      if (this.record.shippingaddress) {
        address.name =
          this.record.shippingaddress.addressee ||
          this.record.shippingaddress.name;
        if (this.record.shippingaddress.address)
          address.address += this.record.shippingaddress.address;
        if (this.record.shippingaddress.address2)
          address.address += `, ${this.record.shippingaddress.address2}`;
        if (this.record.shippingaddress.zip)
          address.address += `, ${this.record.shippingaddress.zip}`;
        if (this.record.shippingaddress.city)
          address.address += `, ${this.record.shippingaddress.city}`;
        if (this.record.shippingaddress.country)
          address.address += `, ${
            this.record.shippingaddress.country.name ||
            this.record.shippingaddress.country
          }`;
      }
      return address;
    },
  },
  components: {
    statistics,
  },
};
</script>
<style scoped>
.notes {
  font-size: 14px;
}
</style>
<style>
.v-alert {
  margin-bottom: 0px;
  margin-top: 12px;
}
pre {
  line-height: 1;
}
</style>
