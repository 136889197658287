 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>

          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                (this.locationsFilter.includes(tab.location) ||
                  !this.locationsFilter.length) &&
                !tab.hidden
            )"
            :key="index"
          >
            <v-data-table
              :headers="tab.headers"
              :items="tab.items"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              :show-select="false"
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              @current-items="getFiltered"
              :item-class="rowClass"
              group-by="Category"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.item="{ item }">
                <router-link
                  :to="{
                    path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                  }"
                  class="link"
                  >{{ item.item.displayname || item.item.name }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>{{
                  item.shippingmethod
                    ? item.shippingmethod.displayname ||
                      item.shippingmethod.name
                    : ""
                }}</span>
              </template>
              <template v-slot:item.planneddateofshipment="{ item }">
                <span>{{
                  item.planneddateofshipment
                    ? new Date(item.planneddateofshipment)
                        .toISOString()
                        .substr(0, 10)
                    : null
                }}</span>
              </template>
              <template v-slot:item.dropship="{ item }">
                <v-chip
                  label
                  :color="item.dropship ? 'red' : 'none'"
                  dark
                  >{{ item.dropship ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.sendtogether="{ item }">
                <v-chip
                  label
                  :color="item.sendtogether ? 'red' : 'none'"
                  dark
                  >{{ item.sendtogether ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.accountonhold="{ item }">
                <v-chip
                  label
                  :color="item.accountonhold ? 'red' : 'none'"
                  dark
                  >{{ item.accountonhold ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.paymentstatus="{ item }">
                <span class="paymentstatus">
                  {{ item.paymentstatus || "Unpaid"
                  }}{{
                    item.paymentdescription
                      ? " - " + item.paymentdescription
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="exportheaders" :items="items" />
                    </v-col>
                    <v-col v-if="false">
                      <v-select
                        dense
                        v-model="term"
                        :items="terms"
                        label="Terms"
                        item-text="name"
                        item-value="_id"
                        outlined
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col
                      ><v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        :disabled="loading"
                        >Refresh</v-btn
                      ></v-col
                    >
                    <v-spacer></v-spacer>
                    <!-- <v-col>
                  <v-btn @click="action" block tile color="primary">Ready To Pack</v-btn>
                    </v-col>-->
                    <v-col class="text-right" v-if="false">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        //{ text: "Location", value: "location" },

        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shippingaddress" },
        // { text: "Manufacturer", value: "manufacturergroup" },
        // { text: "Item", value: "item" },
        { text: "Sales Order", value: "transaction" },
        { text: "Drop Ship", value: "dropship" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Date", value: "date" },
        { text: "Internal Comments", value: "memo" },
        { text: "Instructions", value: "comment" },
        { text: "Ordered", value: "quantity" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Planned Date of Shipment", value: "planneddateofshipment" },
        { text: "Send Together", value: "sendtogether" },
        { text: "Account on Hold", value: "accountonhold" },
        { text: "Balance", value: "balance" },
        { text: "Terms", value: "terms" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "Amount", value: "amount" },
        //{ text: "Amount PLN", value: "amountpln" },
        { text: "Sales Rep", value: "salesrep" },
        //{ text: "Shipping Method", value: "shippingmethod" },
      ],
      headersUK: [
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shippingaddress" },
        { text: "Sales Order", value: "transaction" },
        { text: "Drop Ship", value: "dropship" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Date", value: "date" },
        { text: "Internal Comments", value: "memo" },
        { text: "Instructions", value: "comment" },
        { text: "Ordered", value: "quantity" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Planned Date of Shipment", value: "planneddateofshipment" },
        // { text: "Send Together", value: "sendtogether" },
        //{ text: "Account on Hold", value: "accountonhold" },
        // { text: "Balance", value: "balance" },
        { text: "Terms", value: "terms" },
        { text: "Payment Status", value: "paymentstatus" },
        // { text: "Amount", value: "amount" },
        //   { text: "Sales Rep", value: "salesrep" },
      ],
      exportheaders: [
        { text: "Info", value: "info" },
        { text: "Customer", value: "customer" },
        { text: "Bill Country", value: "billcountry" },
        { text: "Shipping Address", value: "shippingaddress" },
        // { text: "Manufacturer", value: "manufacturergroup" },
        // { text: "Item", value: "item" },
        { text: "Sales Order", value: "transaction" },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Date", value: "date" },
        { text: "Internal Comments", value: "memo" },
        { text: "Instructions", value: "comment" },
        { text: "Ordered", value: "quantity" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Planned Date of Shipment", value: "planneddateofshipment" },
        { text: "Send Together", value: "sendtogether" },
        { text: "Account on Hold", value: "accountonhold" },
        { text: "Balance", value: "balance" },
        { text: "Terms", value: "terms" },
        { text: "Payment Status", value: "paymentstatus" },
        { text: "Amount", value: "amount" },
        //{ text: "Amount PLN", value: "amountpln" },
        { text: "Sales Rep", value: "salesrep" },
        //{ text: "Shipping Method", value: "shippingmethod" },
      ],
      items: [],
      itemsPL: [],
      itemsUK: [],
      itemsIC: [],
      rows: [-1, 50, 100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      customer: null,
      customers: [],
      message: "",
      codamount: null,
      packinginstructions: [],
      packinginstruction: null,
      shippingmethod: null,
      shippingmethods: [],
      filteredItems: [],
      term: [],
      terms: [],
      locationsFilter: [],
      tabs: {},
    };
  },
  async created() {
    this.loading = true;
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      NL: {
        name: "Warehouse NL",
        location: "66e04fcba16b2e1e7d873be8",
        items: [],
        headers: this.headers,
      },
      UK: {
        name: "Warehouse UK (Birmingham)",
        location: "5faa7485371aa739b8a08843",
        items: [],
        headers: this.headers,
        hidden: true,
      },
      IC: {
        name: "Individual Customers",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headersUK,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
    };

    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    console.log(this.locationsFilter);
    await this.getData();
    this.items.forEach((line) => {
      line.customer.displayname =
        line.customer.displayname || line.customer.name;
      this.customers.push(line.customer);
    });
    this.terms = await service("accounting", {
      action: "find",
      data: [{ recordtype: "term" }],
    }).then((response) => {
      response.forEach((v) => {
        v.name = v.displayname || v.name;
      });
      return response;
    });
    this.packinginstructions = await service("lists", {
      action: "find",
      data: [{ recordtype: "packinginstruction" }],
    }).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.shippingmethods = await service("items", {
      action: "find",
      data: [{ recordtype: "shippingitem" }],
    }).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.countries = await service("countries", {
      action: "find",
      data: [{}],
    }).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
      });
      return response;
    });
    this.loading = false;
  },
  watch: {
    selected(value) {
      if (value[0]) {
        let so = value[0];
        this.customer = so.customer;
        this.shippingmethod = so.shippingmethod;
      } else {
        this.customer = null;
        this.shippingmethod = null;
        this.codamount = null;
      }
    },
  },
  updated: function () {
    let rows = document.querySelectorAll("#orderstofulfillpl tbody tr");
    this.filteredItems.forEach((item, index) => {
      if (
        (item.customer.location != item.location || item.customer.isperson) &&
        rows[index]
      ) {
        rows[index].classList.add("otherlocation");
      }
    });
  },
  methods: {
    rowClass(item) {
      if (
        ((item.paymentstatusid && item.paymentstatusid != "fullypaid") ||
          !item.paymentstatusid) &&
        item.terms == "Prepaid"
      ) {
        return "unpaid";
      }
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    async getData() {
      this.loading = true;
      this.items = [];
      this.tabs["PL"].items = [];
      this.tabs["NL"].items = [];
      this.tabs["UK"].items = [];
      this.tabs["IC"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      let result = await service(
        "orders",
        {
          action: "getOrdersToFulfillment",
          terms: this.term,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
      });

      result.items.forEach((item) => {
        item.info = "";
        item.Category = item.source;
        if (item.customer.isperson) {
          item.info = "IC-";
        }
        // if (item.customer.location != item.location) {
        //   if (item.company == "maincompany") {
        //     item.info += "UK-";
        //   }
        //   if (["ozpartsuklimited"].includes(item.company)) {
        //     item.info += "PL-";
        //   }
        // }
        if (item.company == "maincompany") {
          item.info += "PL";
          if (!item.customer.isperson) this.tabs["PL"].items.push(item);
          else this.tabs["IC"].items.push(item);
        }
        if (item.company == "65cf227de8d07217f255104f") {
          item.info += "NL";
          if (!item.customer.isperson) this.tabs["NL"].items.push(item);
          else this.tabs["IC"].items.push(item);
        }
        if (
          ["ozpartsuklimited"].includes(item.company) &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          item.info += "UK";
          if (!item.customer.isperson) this.tabs["UK"].items.push(item);
          else this.tabs["IC"].items.push(item);
        }
        if (
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          item.info += "PeddersUK";
          this.tabs["PeddersUK"].items.push(item);
        }
        if (item.company == "brakespl") {
          this.tabs["BrakesPL"].items.push(item);
        }
      });
      //console.log(result.items)
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    action() {
      let location = null;
      let customer = null;
      let error = false;
      let createdfrom = [];
      this.selected.forEach((select) => {
        if (location && select.location != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location;
        if (customer && select.customer._id != customer) {
          alert("You cannot select more Customer");
          error = true;
        }
        customer = select.customer._id;
        createdfrom.push(select._id);
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "itemfulfillment",
            mode: "create",
            createdfrom: createdfrom,
            location: location,
            message: this.message,
            customer: this.customer,
            packinginstructions: this.packinginstruction,
            shippingmethod: this.shippingmethod,
            codamount: this.codamount,
          },
        });
    },
  },
  components: {
    exportData,
  },
};
</script>
<style>
.otherlocation {
  background: #ececec;
}
</style>