import { render, staticRenderFns } from "./customer.vue?vue&type=template&id=8c62ce1c&scoped=true"
import script from "./customer.vue?vue&type=script&lang=js"
export * from "./customer.vue?vue&type=script&lang=js"
import style0 from "./customer.vue?vue&type=style&index=0&id=8c62ce1c&prod&scoped=true&lang=css"
import style1 from "./customer.vue?vue&type=style&index=1&id=8c62ce1c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c62ce1c",
  null
  
)

export default component.exports