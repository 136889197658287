var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return (this$1.locationsFilter.includes(tab.location) ||
                !this$1.locationsFilter.length) &&
              !tab.hidden; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":tab.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":false,"search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort,"item-class":_vm.rowClass,"group-by":"Category"},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toISOString().substr(0, 10)))])]}},{key:"item.transaction",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/view/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.item",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.customer.collection) + "/" + (item.customer.recordtype) + "/view/" + (item.customer._id)),
                }}},[_vm._v(_vm._s(item.customer.displayname || item.customer.name))])]}},{key:"item.shippingmethod",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shippingmethod ? item.shippingmethod.displayname || item.shippingmethod.name : ""))])]}},{key:"item.planneddateofshipment",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planneddateofshipment ? new Date(item.planneddateofshipment) .toISOString() .substr(0, 10) : null))])]}},{key:"item.dropship",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.dropship ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.dropship ? "Yes" : "No"))])]}},{key:"item.sendtogether",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.sendtogether ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.sendtogether ? "Yes" : "No"))])]}},{key:"item.accountonhold",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.accountonhold ? 'red' : 'none',"dark":""}},[_vm._v(_vm._s(item.accountonhold ? "Yes" : "No"))])]}},{key:"item.paymentstatus",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"paymentstatus"},[_vm._v(" "+_vm._s(item.paymentstatus || "Unpaid")+_vm._s(item.paymentdescription ? " - " + item.paymentdescription : "")+" ")])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.exportheaders,"items":_vm.items}})],1),(false)?_c('v-col',[_c('v-select',{attrs:{"dense":"","items":_vm.terms,"label":"Terms","item-text":"name","item-value":"_id","outlined":"","hide-details":"","clearable":"","multiple":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1):_vm._e(),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),(false)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1):_vm._e()],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }